<template>
<v-main>
  <div class="fill-height d-flex flex-column align-center justify-center">
    <div>Dieser Link ist abgelaufen.</div>
    <div class="text-center px-4"><router-link to="/home">Klicke hier</router-link>, um zur Home-Seite zu gelangen.</div>
  </div>
</v-main>
</template>

<script>
export default {
  name: "ExpiredLink"
};
</script>

<style scoped>

</style>